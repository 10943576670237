import React from 'react'
import PropTypes from 'prop-types'
import Networks from '../molecules/Networks'
import Availability from '../molecules/Availability'
import ThemeSwitch from '../molecules/ThemeSwitch'
import LogoUnit from '../molecules/LogoUnit'
import styles from './Header.module.scss'
import { graphql, StaticQuery } from 'gatsby'
import { useMeta } from '../../hooks/use-meta'

Header.propTypes = {
  minimal: PropTypes.bool,
  hide: PropTypes.bool
}

const query = graphql`
  query {
    gitCommit(latest: { eq: true }) {
      hash
    }
  }
`

export default function Header({ location, minimal, hide }) {
  const { availability } = useMeta()

  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <>
            <ThemeSwitch gitHash={data.gitCommit.hash} />
            <header className={minimal ? styles.minimal : styles.header}>
              {!hide && (
                <>
                  <LogoUnit minimal={minimal} />
                  <Networks hide={minimal} />
                  <Availability hide={minimal && !availability.status} />
                </>
              )}
              {location.pathname === '/' && <h3 onClick={() => {
                const element = document.getElementById("work")
                element.scrollIntoView()
              }
              }>↓</h3>}
            </header>
          </>
        )

      }}
    />
  )

}
