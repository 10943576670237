module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","siteUrl":"https://nicolagenesin.com","matomoUrl":"https://google.com","localScript":"/piwik.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"nicola genesin","short_name":"mk","start_url":"/","background_color":"#e7eef4","theme_color":"#e7eef4","icon":"src/images/favicon.png","display":"standalone","cache_busting_mode":"name","theme_color_in_head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
