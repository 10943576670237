import React, { memo } from 'react'
import Helmet from 'react-helmet'
import { useMeta } from '../../hooks/use-meta'

const TypekitScript = typekitID => (
  <script>
  </script>
)

const Typekit = () => {
  const { typekitID } = useMeta()

  return (
    <Helmet>
      <link rel="preconnect" href="https://typekit.com" />
      <link rel="preconnect" href="https://use.typekit.net" />
      <link rel="preconnect" href="https://p.typekit.net" />

      {TypekitScript(typekitID)}
    </Helmet>
  )
}

export default memo(Typekit)
